import React, { useEffect, useState } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import choose1 from '../assets/choose1.png'
import choose2 from '../assets/choose2.jpg'
import dots from '../assets/dots.png'
import aboutus from '../assets/aboutus.PNG'
export default function Whychoose() {
  const [experience,setExperience]=useState(0);
  const [industry,setIndustry]=useState(0);
  const [projects,setProjects]=useState(0);
  

  useEffect(() => {
    AOS.init({ duration: 1000 });
    const ind = setInterval(() => {
      setIndustry(prev => {
        if (prev >= 660) {
          clearInterval(ind);
          return prev; 
        }
        return prev + 1;
      });
    }, 50);
    const exp = setInterval(() => {
      setExperience(prev => {
        if (prev >= 30) {
          clearInterval(exp);
          return prev; 
        }
        return prev + 1;
      });
    }, 50);
    const pro = setInterval(() => {
      setProjects(prev => {
        if (prev >= 800) {
          clearInterval(pro);
          return prev; 
        }
        return prev + 1;
      });
    }, 50);
    return () => clearInterval(pro);
    }, []);

  
    // console.log(projects);
    
  
  return (
    <>
   
    {/* <div className="bg-white   overflow-hidden  flex justify-center items-center ">
      <div className='flex flex-col-reverse gap-20 justify-center  items-center  lg:flex-row-reverse lg:justify-between mt-10 mb-10  '>

        <div className='right pr-8 pl-8 mt-6 '>
          <img src={aboutus} alt="" className='mb-3' />
          <h2 className='text-3xl font-bold font-serif capitalize' data-aos="fade-down">Why Choose Heartland</h2>
          <h2 className='text-3xl font-bold font-serif' data-aos="zoom-in-left">RCM?</h2>
          <p className='mt-2 text-gray-500' data-aos="zoom-in-left">Our easy-to-use programming assists you with board training and</p>
          <p className='text-gray-500' data-aos="zoom-in-left">charging activities. examine patients. Earn compensation. It is</p>
          <p className='text-gray-500' data-aos="zoom-in-left">simply necessary.</p>
            <div className=" mx-auto px-4 mt-4" data-aos="zoom-in">
              <div className="w-full bg-[#087dce] text-white shadow-md rounded-lg">
                <div className="p-8">
                  <ul className="flex flex-wrap justify-start">
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Reasonable</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Innovative</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Convenient</span>
                    </li>
                  </ul>
                  <ul className="flex flex-wrap justify-start">
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Transparent</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Ingenious</span>
                    </li>
                    <li className="flex items-center m-2">
                      <span className="text-white mr-2">
                        <i aria-hidden="true" className="fas fa-check"></i>
                      </span>
                      <span>Precise</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
        <div className='w-96 h-80'>
          <img src={choose1} data-aos="zoom-in" className='relative rounded-xl' alt="" />
          <img src={choose2} alt="" data-aos="zoom-in-up" className='absolute w-60  rounded-2xl -mt-28 -ml-14' />


        </div>
      </div>
    </div> */}
   
   <section className="py-16 w-full overflow-hidden bg-gray-100">
      <div className=" mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl p-4">
        <div className="flex flex-wrap -mx-4">
       
          <div className="w-full md:w-1/2 px-4 flex">
            <img
              src="https://demos.layoutdivi.com/wp-content/uploads/2022/11/Divi-About-Us-Section-Hero-Image-1.jpg"
              alt="Divi About Us Section Hero Image"
              className="w-full  mb-8"
            />
            <div className='bg-white h-fit pt-3 pl-4 pr-4 mt-auto mb-auto -ml-32'>
            <div className="text-center mb-8 border-b-2 border-transparent hover:border-brown-700">
              <p className="text-4xl text-brown-700">{industry}</p>
              <h3 className="text-gray-600">Industrial Solutions</h3>
            </div>
            <div className="text-center mb-8 border-b-2 border-transparent hover:border-brown-700">
              <p className="text-4xl text-brown-700">{experience}</p>
              <h3 className="text-gray-600">Years Experience</h3>
            </div>
            <div className="text-center mb-8 border-b-2 border-transparent hover:border-brown-700">
              <p className="text-4xl text-brown-700">{projects}</p>
              <h3 className="text-gray-600">Projects Completed</h3>
            </div>
            </div>
          </div>

          <div className="w-full md:w-1/2 px-4">
            <div className="mb-8">
              <p className="text-lg font-semibold border-b-2 border-orange-500 pb-2 inline-block">About Company</p>
            </div>
            <div className="mb-8">
              <h2 className="text-2xl font-bold mb-4">We’re More than an Industrial Company</h2>
              <p className="italic text-brown-700">Lorem are many variations of passages of Lorem Ipsum available, but the majority have suffered.</p>
            </div>
            <div className="mb-8">
              <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour.</p>
            </div>

            <Blurb icon="check-circle" title="Invest in your simply neighborhood" />
            <Blurb icon="check-circle" title="Support people in free text extreme need" />
            <Blurb icon="check-circle" title="Largest global industrial business community" />
            <Blurb icon="check-circle" title="Share your love for factory community" />
          </div>
        </div>
      </div>
    </section>
 

    </>
  );
};

const Blurb = ({ icon, title }) => {
  return (
    <div className="flex items-start mb-8">
      <span className="text-brown-700 mr-4 text-2xl">
        <i className={`fas fa-${icon}`}></i>
      </span>
      <h4 className="text-lg font-semibold">{title}</h4>
    </div>
  );
};


    {/* </> */}
//   )
// }
