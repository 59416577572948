import React, { useEffect } from 'react'
import AOS from 'aos';
import 'aos/dist/aos.css';
import process from '../assets/process.PNG'
import icon1 from '../assets/New folder/icon1.PNG'
import icon2 from '../assets/New folder/icon2.PNG'
import icon3 from '../assets/New folder/icon3.PNG'
import icon4 from '../assets/New folder/icon4.PNG'
import icon5 from '../assets/New folder/icon5.PNG'
import icon6 from '../assets/New folder/icon6.PNG'
import icon7 from '../assets/New folder/icon7.PNG'
import icon8 from '../assets/New folder/icon8.PNG'
import icon9 from '../assets/New folder/icon9.PNG'
export default function Process() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  const slides = [
    {
      title: "Register Patients",
      description: "We provide designs to fit any financial strategy. Heartland System uses the cloud to operate, saving your money. No compelling motive exists to invest money in servers.",
      icon: icon1,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
      number: "01",
    },
    {
      title: "Affirm financial responsibility",
      description: "The Heartland System is easy to use and doesn't require any training. Information about patients and organizations are easily accessible because of its attractive interface.",
      icon: icon2,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/closeup-hands-business-meeting.jpg",
      number: "02",
    },
    {
      title: "Patient check-in and check-out",
      description: "Take ownership of your trainings. Progressively introduce tolerable improvements. Run provides a breakdown of all of your financial assets.",
      icon: icon3,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "03",
    },
    {
      title: "claims compliance",
      description: "Be careful where you receive your training. While seeing a patient, update your records with HeartlandRCM System Mobile to avoid returning to a PC.",
      icon: icon4,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
      number: "04",
    },
    {
      title: "claims scrubbing",
      description: "It leverage advanced technology and streamlined processes to optimize revenue cycles, improve financial performance in healthcare organizations.",
      icon: icon5,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/bookkeeping-accounting-taxes-615384.jpg",
      number: "05",
    },
    {
      title: "followup on claims",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: icon6,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "06",
    },
    {
      title: "patient statements",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: icon7,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "06",
    },
    {
      title: "handle payments and collections",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: icon8,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "06",
    },
    {
      title: "financial reports",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: icon9,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
      number: "06",
    },
  ];
  return (
    <section className="w-full py-8 bg-white sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl">
      <div className="text-center mb-6 ">
        <img src={process} className='m-auto' alt="" />
        <h2 className="text-4xl font-bold uppercase" data-aos="zoom-in">Billing Process </h2>
      </div>
      <p data-aos="fade-up" className='text-center pl-20 pr-20 lg:pl-72 lg:pr-64' data-aos-duration="1000">
        Similar to clinical coding, clinical pricing may seem complex and overwhelming, but it actually consists of just eight simple steps.
      </p>
      <div className='lg:p-10 p-5 lg:pl-12 lg:pr-12 flex gap-6 flex-wrap justify-center 2xl:justify-between'>
        {slides.map((slide, index) => (
          <div class="block max-w-sm 2xl:max-w-md  p-6 w-full bg-white border hover:text-white hover:bg-gradient-to-r from-green-400 to-blue-400  border-gray-200 rounded-lg shadow hover:bg-gray-100 dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700">
            <div className=" text-center mb-5">
                    {/* <span className="" data-aos="zoom-in"> */}
                      <img src={slide.icon} alt="" data-aos="zoom-in" className='m-auto'/>
                    {/* </span> */}
                  </div>
            <h5 class="mb-2 text-xl uppercase font-bold tracking-tight text-gray-900 dark:text-white text-center" data-aos="fade-up">{slide.title}</h5>
          </div>

        ))}

      </div>
    </section>
  );
};

