import React from 'react'
import mdetail from '../assets/subservices/mdetail.jpg'
import MBSBenefits from '../components/MBSBenefits'
import MBSwipper from '../components/MBSwipper'
import MBsecondswiper from '../components/MBsecondswiper'
import { useNavigate } from 'react-router-dom';
export default function MedicalBillingServices() {
  let navigate = useNavigate();
  const handlecontact = () => {
    navigate('/Contact')
  }

  return (
    <div className='mt-20'>
      <div className='border overflow-hidden'>
        <div className='bg-fixed relative'
          style={{

            backgroundImage: `url(${mdetail})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            height: '450px', // Adjust height as needed
            opacity: 0.7,
            width: '100vw',
            // overflow:'hidden'
          }}>
          {/* <img src={hero2} className='w-full '  alt="" /> */}
          <div className="absolute backdrop-blur-xl w-[100%] mt-6 lg:mt-36 text-wrap  ">
            <h1 className='  text-center  font-bold text-2xl md:text-6xl text-[#003680] mb-2 '>Medical Billing Services</h1>
            <p className='  text-center   md:font-bold text-sm md:text-2xl mt-3 mb-3 text-wrap pl-12 pr-12 text-white  '>Ambulatory Surgery Centers (ASCs) face similar billing challenges as hospitals but often have a smaller billing team to handle these complexities. Outsourcing medical billing to ThriveMed RCM alleviates the burden of keeping up with intricate coding regulations, allowing you to focus on managing a successful surgery center.</p>
          </div>
        </div>
      </div>

      <MBSBenefits />
      <MBSwipper />
      <MBsecondswiper />
     
      <div className='bg-blue-950'>

        <div className='bg-blue-950 p-5 sm:max-w-xl sm:text-xl md:max-w-full md:text-xl lg:max-w-screen-2xl lg:text-xl m-auto'>
          <div className='flex   flex-col gap-6  lg:flex-row justify-between align-middle lg:ml-8 lg:mr-8 sm:mr-4 sm:ml-4 lg:pl-12 lg:pr-12 pl-4 pr-4 pt-4 pb-4'>
            <div className='content flex flex-col justify-center'>
              <p className=' text-xl 2xl:text-3xl mb-4 text-center   lg:text-start capitalize text-white'>Outsourcing medical billing offers an efficient solution to streamline complex billing processes contact us today for more information or to request a demo.</p>
            </div>
            <button
              // data-aos="zoom-in"
              onClick={handlecontact}
              type="button"
              className="text-white  w-52 h-14 mt-4 mx-auto border-4 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
