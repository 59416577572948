import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import features from '../assets/features.PNG'
import precise from '../assets/iconsswiper/precise.PNG'
import convenient from '../assets/iconsswiper/convenient.PNG'
import ingenious from '../assets/iconsswiper/ingenious.PNG'
import reasonable from '../assets/iconsswiper/reasonable.PNG'
import innovatuve from '../assets/iconsswiper/innovatuve.PNG'
export default function MBSwipper() {
  const slides = [
    {
      title: "Reasonable",
      description: "We provide designs to fit any financial strategy. Heartland System uses the cloud to operate, saving your money. No compelling motive exists to invest money in servers.",
      icon: reasonable,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
    },
    {
      title: "Precise",
      description: "The Heartland System is easy to use and doesn't require any training. Information about patients and organizations are easily accessible because of its attractive interface.",
      icon: precise,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/closeup-hands-business-meeting.jpg",
    },
    {
      title: "Ingenious",
      description: "Take ownership of your trainings. Progressively introduce tolerable improvements. Run provides a breakdown of all of your financial assets.",
      icon: ingenious,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
    },
    {
      title: "Convenient",
      description: "Be careful where you receive your training. While seeing a patient, update your records with HeartlandRCM System Mobile to avoid returning to a PC.",
      icon: convenient,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-patient-handshake-5835367.jpg",
    },
    {
      title: "Innovative",
      description: "It leverage advanced technology and streamlined processes to optimize revenue cycles, improve financial performance in healthcare organizations.",
      icon: innovatuve,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/06/bookkeeping-accounting-taxes-615384.jpg",
    },
    {
      title: "Transparent",
      description: "Transparent medical billing services openly communicate treatment costs, provide clear information, and foster collaboration to enhance patient satisfaction.",
      icon: reasonable,
      imgSrc: "https://heartlandrcm.com/wp-content/uploads/2023/08/doctor-tomograph-study-1228627.jpg",
    },
  ];
  return (
    <section className="w-full py-8 bg-[#eff5f3]">
      <div className="text-center mb-6 ">
        <h2 className="text-xl font-bold">Outsource Medical Billing Services for Greater Revenue and Peace of Mind</h2>
      </div>
      <div className='lg:p-10 p-5 lg:pl-16 lg:pr-16'>
        <Swiper
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={1}
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 2 },
          }}
          loop={true}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          // navigation={true}
          // pagination={{ clickable: true }}
        >
          {slides.map((slide, index) => (
            <SwiperSlide key={index} className="p-4">
            
              <div className="hipa-container">
                <div className="bg-white text-gray-700 p-12 lg:p-20  hover-effect">
                  <img src={slide.icon} className="m-auto  mb-5" data-aos="zoom-in" />
                  <p className="font-bold text-xl text-center mb-5" data-aos="fade-up">{slide.title}</p>
                  <div className="desc text-center" data-aos="fade-up">
                    {slide.description}
                  </div>
                  <div className="arrow text-center">
                    <span className="icon" data-aos="zoom-in">
                      <i className="fas fa-arrow-right"></i>
                    </span>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  )
}
