// import React from 'react'
// import { useEffect } from 'react';
// import Marquee from "react-fast-marquee";
// import c1 from '../assets/c1.PNG';
// import c2 from '../assets/c2.PNG';
// import c3 from '../assets/c3.PNG';
// import c4 from '../assets/c4.PNG';
// import c5 from '../assets/c5.PNG';
// import c6 from '../assets/c6.PNG';
// import c7 from '../assets/c7.PNG';
// import c8 from '../assets/c8.PNG';
// import c9 from '../assets/c9.PNG';
// import c10 from '../assets/c10.PNG';
// import c11 from '../assets/c11.PNG';
// import c12 from '../assets/c12.PNG';
// import c13 from '../assets/c13.PNG';
// import c14 from '../assets/c14.PNG';
// import c15 from '../assets/c15.PNG';
// import c16 from '../assets/c16.PNG';
// import c17 from '../assets/c17.PNG';
// import c18 from '../assets/c18.PNG';
// import c19 from '../assets/c19.PNG';
// import AOS from 'aos';
// import 'aos/dist/aos.css';
// import 'swiper/css';
// import 'swiper/css/autoplay';
// import { Swiper, SwiperSlide } from 'swiper/react';
// import  Autoplay  from 'swiper';

// export default function Companieslogos() {
//   useEffect(() => {
//     AOS.init();
//   }, [])

//   let logos=[
//     {
//       img1:c1,
//     },
//     {
//       img1:c2,
//     },
//     {
//       img1:c3,
//     },
//     {
//       img1:c4,
//     },
//     {
//       img1:c5,
//     },
//     {
//       img1:c6,
//     },
//     {
//       img1:c7,
//     },
//     {
//       img1:c8,
//     },
//     {
//       img1:c9,
//     },
//     {
//       img1:c10,
//     },
//     {
//       img1:c11,
//     },
//     {
//       img1:c12,
//     },
//     {
//       img1:c13,
//     },
//     {
//       img1:c14,
//     },
//     {
//       img1:c15,
//     },
//     {
//       img1:c16,
//     },
//     {
//       img1:c17,
//     },
//     {
//       img1:c18,
//     },
//     {
//       img1:c19,
//     },
//   ]
//   return (
//     <div className="bg-white lg:pl-32 lg:pr-32 p-12 lg:p-24">
//       <p className='capitalize text-center font-bold text-blue-700 mb-5' data-aos="fade-down">EXPERTIES IN</p>
//       <p className='font-bold text-4xl text-center mb-5' data-aos="fade-up" >PM/EHR</p>
//       <div className='flex justify-around '>
//       <Marquee 
//       pauseOnHover={true}


//       >
//        {
//         images.map((img)=>{
//           return(
//             <img src={img.img1}  data-aos="fade-left"  className='mr-6 ml-6  border' alt="images" />
//           )
//         })
//        }
//       </Marquee>
//        </div>
//     </div>



//   )
// }



import React, { useEffect } from 'react';
import Marquee from "react-fast-marquee";
import c1 from '../assets/c1.PNG';
import c2 from '../assets/c2.PNG';
import c3 from '../assets/c3.PNG';
import c4 from '../assets/c4.PNG';
import c5 from '../assets/c5.PNG';
import c6 from '../assets/c6.PNG';
import c7 from '../assets/c7.PNG';
import c8 from '../assets/c8.PNG';
import c9 from '../assets/c9.PNG';
import c10 from '../assets/c10.PNG';
import c11 from '../assets/c11.PNG';
import c12 from '../assets/c12.PNG';
import c13 from '../assets/c13.PNG';
import c14 from '../assets/c14.PNG';
import c15 from '../assets/c15.PNG';
import c16 from '../assets/c16.PNG';
import c17 from '../assets/c17.PNG';
import c18 from '../assets/c18.PNG';
import c19 from '../assets/c19.PNG';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';




export default function CompaniesLogos() {
  useEffect(() => {
    AOS.init();
  }, []);

  const logos = [
    c1, c2, c3, c4, c5, c6, c7, c8, c9, c10,
    c11, c12, c13, c14, c15, c16, c17, c18, c19
  ];

  return (
    <section className="bg-white xl:pl-16 xl:pr-16 p-12 md:flex-row  flex flex-col lg:justify-between xl:justify-center w-full">
      <div className=''>
        <p className="capitalize text-4xl mt-6 font-bold bg-gradient-to-r from-green-400 to-blue-400 bg-clip-text text-transparent mb-5" data-aos="fade-down">
          EXPERTISE IN PM/EHR
        </p>
        {/* <p className='font-bold text-xl text-center mb-12' data-aos="fade-up" >Solution to happy companies</p> */}
      </div>
      <div className='lg:ml-10 md:w-8/12'>

        <Swiper
          className=''
          modules={[Autoplay]}
          spaceBetween={30}
          slidesPerView={2}
          autoplay={{ delay: 1500, disableOnInteraction: false }}
          loop={true}
          breakpoints={{
            360: { slidesPerView: 2, spaceBetween: 20 },
            375: { slidesPerView: 2, spaceBetween: 20 },
            390: { slidesPerView: 2, spaceBetween: 20 },
            412: { slidesPerView: 2, spaceBetween: 20 },
            414: { slidesPerView: 2, spaceBetween: 20 },
            430: { slidesPerView: 2, spaceBetween: 20 },
            640: { slidesPerView: 2, spaceBetween: 20 },
            768: { slidesPerView: 3, spaceBetween: 30 },
            1024: { slidesPerView: 3, spaceBetween: 10 },
            1280: { slidesPerView: 3, spaceBetween: 10 },
            1536: { slidesPerView: 3, spaceBetween: 10 },
          }}
        >
          {logos.map((logo, index) => (
            <SwiperSlide key={index}>
              <img src={logo} alt={`Logo ${index + 1}`} className="mx-auto w-48 h-24" />
            </SwiperSlide>
          ))}
        </Swiper>

      </div>
    </section>
  );
}
