import React, { useEffect } from 'react';
import AOS from 'aos';
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/autoplay';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay } from 'swiper/modules';
import c1 from '../assets/medbillingswiper/1m.PNG';
import c2 from '../assets/medbillingswiper/2m.PNG';
import c3 from '../assets/medbillingswiper/3m.PNG';
import c4 from '../assets/medbillingswiper/4m.PNG';
import c5 from '../assets/medbillingswiper/5m.PNG';
export default function MBsecondswiper() {
  useEffect(() => {
    AOS.init();
  }, []);
  const logos = [
    c1, c2, c3, c5, c4
  ];
  return (
    <section className="bg-white lg:pl-32 lg:pr-32 p-12 lg:p-24">
    <p className='font-bold text-4xl text-center mb-12' data-aos="fade-up" >How ThriveMed Increases Client Revenue</p>
    <Swiper
      modules={[Autoplay]}
      spaceBetween={30}
      slidesPerView={2}
      autoplay={{ delay: 1500, disableOnInteraction: false }}
      loop={true}
      breakpoints={{
        360: { slidesPerView: 2, spaceBetween: 20 },
        375: { slidesPerView: 2, spaceBetween: 20 },
        390: { slidesPerView: 2, spaceBetween: 20 },
        412: { slidesPerView: 2, spaceBetween: 20 },
        414: { slidesPerView: 2, spaceBetween: 20 },
        430: { slidesPerView: 2, spaceBetween: 20 },
        640: { slidesPerView: 2, spaceBetween: 20 },
        768: { slidesPerView: 3, spaceBetween: 30 },
        1024: { slidesPerView: 3, spaceBetween: 30 },
        1280: { slidesPerView: 5, spaceBetween: 20 },
        1536: { slidesPerView: 5, spaceBetween: 20 },
      }}
    >
      {logos.map((logo, index) => (
        <SwiperSlide key={index}>
          <img src={logo} alt={`Logo ${index + 1}`} className="mx-auto w-48 h-24" />
        </SwiperSlide>
      ))}
    </Swiper>
  </section>
  )
}
